<template>
  <div style="">
    <portal to="title-page">
      <h1>Reporte de reservaciones por status</h1>
    </portal>
    <v-card
      elevation="2"
    >
      <v-card-text>
        <!-- <Loading :active="loader" :fullPage="false"/> -->
        <Loading :active="loading"/>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.startDate"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                @input="menuStartDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.endDate"
                  label="Fecha fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                @input="menuEndDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.status"
              :items="status"
              label="Status"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.tour"
              :items="tours"
              item-text="name"
              item-value="id"
              label="Tour"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.panel"
              :items="panels"
              label="Panel"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
             <v-select
              v-model="filters.rate"
              :items="rates"
              label="Tarifa"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
            ></v-select>
          </v-col> -->
          
          <v-col cols="12" sm="4" md="5" class="py-0">
            <v-btn
              class="mb-md-3"
              small
              elevation="2"
              color="primary"
              @click="searchReservations()"
            >
              <v-icon>mdi-magnify</v-icon> Buscar
            </v-btn>
            <v-btn 
              class="ml-2 mb-md-3 white--text"
              small 
              color="red"
              @click="generatePdf"
            >
              <v-icon>mdi-file-pdf-box</v-icon> Descargar
            </v-btn>
          </v-col>
          <!-- <v-col cols="6" sm="6" md="6">
            <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
          </v-col> -->
        </v-row>
        <div v-if="result" class="table-responsive">
          <table class="table table-bordered text-center">
            <thead>
              <tr>
                <th>No.</th>
                <th>Panel</th>
                <th>ID Reserva</th>
                <th>Tour</th>
                <th>Status</th>
                <th>Total MXN</th>
                <th>Total USD</th>
                <th>Pago Aplicado MXN</th>
                <th>Pago Aplicado USD</th>
                <th>Balance MXN</th>
                <th>Balance USD</th>
                <th>Fecha Tour</th>
              </tr>
            </thead>
            <tbody>
              <!-- <template
                v-for="(tour, index) in result.tours"
              > -->
                <!-- <tr v-for="(item, i) in tour.items" :key="i+index"> -->
                <tr v-for="(item, i) in result.reservations" :key="i">
                  <td >{{ i + 1 }}</td>
                  <td >{{ panelsNames[item.reservation.panel] }}</td>
                  <td >{{ item.reservation.code }}</td>
                  <td >{{ item.reservation.tour }}</td>
                  <td >{{ item.reservation.paymentStatus }}</td>
                  <td >{{ item.reservation.currency == 'mxn' ? numberFormat(item.reservation.total) : '' }}</td>
                  <td >{{ item.reservation.currency == 'usd' ? numberFormat(item.reservation.total) : '' }}</td>
                  <td >{{ item.reservation.currency == 'mxn' ? item.amountPaid : '' }}</td>
                  <td >{{ item.reservation.currency == 'usd' ? item.amountPaid : '' }}</td>
                  <td >{{ item.reservation.currency == 'mxn' ? item.balance : '' }}</td>
                  <td >{{ item.reservation.currency == 'usd' ? item.balance : '' }}</td>
                  <td >{{ $moment.unix(item.reservation.date.seconds).format('DD-MM-YYYY') }}</td>
                </tr>
              <!-- </template> -->
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5">Totales</th>
                <th>{{ numberFormat(result.totalMXN) }}</th>
                <th>{{ numberFormat(result.totalUSD) }}</th>
                <th>{{ numberFormat(result.amountPaidMXN) }}</th>
                <th>{{ numberFormat(result.amountPaidUSD) }}</th>
                <th>{{ numberFormat(result.balanceMXN) }}</th>
                <th>{{ numberFormat(result.balanceUSD) }}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import Reservation from "@/models/Reservation"
import Tour from "@/models/Tour"
// import DailyPDF from "@/pdfs/reports/DailyPDF"
import ReservationsStatusPDF from "@/pdfs/reports/ReservationsStatusPDF"
import Loading from "@/components/Loading"
Vue.use('Loading', Loading);
import "@/assets/css/tables.css"
import {NumberFormat} from "@/helpers/NumberFormat";
export default {
  name:'TaquillaReservationStatusReport',
  //components: { Loading },
  data() {
    let date = this.$moment()
    
    return {
      filters:{
        status: {name: 'Todos', value: ''},
        tour: '',
        rate: '',
        panel: '',
        startDate:date.format('YYYY-MM-DD'),
        endDate:date.format('YYYY-MM-DD'),
      },
      //date: '2021-02-12',
      reservationModel: new Reservation,
      //items: null,
      result: null,
      menuStartDate: false,
      menuEndDate: false,
      loading: false,
      tours: [{name: 'Todos', id: ''}],
      panels: [
        {name:'Todos', value: ''},
        {name:'Web', value: 'website'},
        {name:'Taquilla', value: 'taquilla'},
      ],
      rates: [
        {name:'Todos', value: ''},
        {name:'Local', value: 'local'},
        {name:'Regular', value: 'foreign'},
        {name:'Nacional', value: 'coupon'},
      ],
      status: [
        {name:'Todos', value: ''},
        {name:'Pending', value: 'pending'},
        {name:'Balance', value: 'balance'},
        {name:'Confirmed', value: 'confirmed'},
      ],
      panelsNames: {
        website: 'Web',
        taquilla: 'Taquilla',
      },
      //unsubscribe: ,
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'Taquilla')
  },
  async mounted() {
    let tourModel = new Tour
    let snapTours = await tourModel.all().get()
    snapTours.forEach(i => {
      this.tours.push({name: i.data().name, id: i.data().id})
    })
    //await this.searchReservations();
  },
  methods:{
    async searchReservations() {
      /*if (this.unsubscribe) {
        this.unsubscribe();
      }*/
      this.loading = true
      let filters = {
        tourId: this.filters.tour.id,
        status: this.filters.status.value,
        panel: this.filters.panel.value,
        rate: this.filters.rate.value,
        startDate: this.$moment(this.filters.startDate),
        endDate: this.$moment(this.filters.endDate),
      }
      this.result = await this.reservationModel.getDataReservationsStatusReport(filters)
      //this.items = this.result.items
      this.loading = false
    },
    numberFormat(number) {
      return NumberFormat.format(number, 2, ',', '.')
    },
    async generatePdf() {
      let filters = {...this.filters}
      filters.startDate = this.$moment(this.filters.startDate)
      filters.endDate = this.$moment(this.filters.endDate)
      const pdf = new ReservationsStatusPDF({
        filters: filters,
        data:this.result,
        date:this.$moment()
      })
      this.loading = true
      const file = await pdf.create()
      file.download()
      this.loading = false
    }
  },
  //computed: mapState(['dialogConfirmationInfo'])
}
</script>